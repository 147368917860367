<template>
	<div class="mobphone">
		<div class="mobphone-nei">
			<div class="mobphone-main">
				<div class="ph-title">
					<p>绑定手机号</p>
					<p class="xian"></p>
				</div>
				<div class="gongdan">
					<div class="dan">
						<div class="text"><span>*</span>手机号:</div>
						<div class="inputbox">
							<el-input v-model="Phoneinput" placeholder="请输入手机号"></el-input>
						</div>
					</div>
					<div class="dan yzm">
						<div class="text"><span>*</span>验证码:</div>
						<div class="inputbox">
							<el-input v-model="Codeinput" placeholder="请输入验证码"></el-input>
							<el-button type="primary" @click="toCode" :disabled="delenteAnniu" plain round>{{counditem}}</el-button>
						</div>
					</div>
					<div class="anniu duihao">
						<div class="xuanzek">
							<el-checkbox v-model="checked">绑定手机号实时推送微信消息、解锁工具箱功能</el-checkbox>
						</div>
						<el-button type="primary" @click="onSubmit" round>提交绑定</el-button>
					</div>
				</div>
				<div class="enderQueren">
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data () {
		return {
			delenteAnniu:false,
			Phoneinput:'',//手机号
			Codeinput:'',//验证码
			counditem:"发送验证码",
			daojishi:60,
			jishiqi:null,
			checked: false
		}
	},

	mounted(){
		console.log(11111111111)
		if(this._isMobile){
			window.location.href = '/mobphone5g';
		}
		console.log(this.$route)

	},

	methods: {
		// 测试用
		// onceshi(){
		// 	this.$service.get(this.$api.getWxpublicArt,'', headers, (res)=> {
		// 		console.log(123)
		// 		if(res.code == '200'){

		// 		}
		// 	})
		// },
		//发送验证码
		toCode(){
			if(!(/^1(3|4|5|6|7|8|9)\d{9}$/.test(this.Phoneinput))){
				this.$message({message: '请正确输入手机号',type: 'warning'});
			}else{
				var param = {
					account:this.Phoneinput,
				}
				this.$service.get(this.$api.sendMobileMsg,param, (res)=> {
					if(res.code == '200'){
						this.delenteAnniu = true
						this.jishiqi = setInterval(() => {
							this.daojishi = this.daojishi - 1
							this.counditem = this.daojishi + 's后重新发送'
							console.log(this.daojishi)
							if(this.daojishi == 0){
								clearInterval(this.jishiqi)
								this.delenteAnniu = false
								this.counditem = '发送验证码'
								this.daojishi = 60
							}
						}, 1000);
					}
				})
			}
		},
		//提交绑定手机号
		onSubmit(){
			if(this.Phoneinput == ''){this.$message({message: '请输入手机号',type: 'warning'});return}
			if(this.Codeinput == ''){this.$message({message: '请输入验证码',type: 'warning'});return}
			if(!this.checked){this.$message({message: '请确认推送微信消息、解锁工具箱功能',type: 'warning'});return}
			var param = {
				account:this.Phoneinput,
				sms_code:this.Codeinput,
			}
			this.$service.post(this.$api.bindMobile,param, (res)=> {
				if(res.code == '200'){
					this.$message({message: '绑定成功',type: 'warning'})
					setTimeout(() => {
                    	window.location.href = '/workList';
					}, 1000);
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.mobphone{
	width: 100%;
	height: 100vh;
	// text-align: center;
	background: url('https://erlangcha.oss-cn-beijing.aliyuncs.com/usergongju/phonebg.jpg') left no-repeat;
	position: relative;
	.mobphone-nei{
		position: absolute;
		left: calc(50% - 515px);
		top: calc(50% - 254px);
		width: 1029px;
		height: 527px;
		background: url('https://erlangcha.oss-cn-beijing.aliyuncs.com/usergongju/phonebg1.png') center no-repeat;
	}
	.mobphone-main{
		width: 490px;
		padding: 30px;
		.ph-title{
			text-align: center;
			padding-bottom: 30px;
			border-bottom: 1px solid #ededed;
			p{
				font-size: 26px;
				color: #006fc5;
				font-weight: 600;
			}
			.xian{
				width: 60px;
				height: 3px;
				border-radius: 3px;
				background: #006fc5;
				margin: 0 auto;
				margin-top: 10px;
			}
		}
		.gongdan{
			margin-top: 30px;
			padding: 0 20px;
			.dan{
				border-bottom: 1px solid #ddd;
				padding-bottom: 10px;
				.text{
					min-width: auto;
					font-size: 16px;
				}
				.el-input{
					.el-input__inner{
						border: none;
					}
				}
			}
			.yzm{
				.inputbox{
					display: flex;
					.el-input{
						width: 150px;
						margin-right: 10px;
					}
				}
			}
			.anniu{
				width: 100%;
				margin-top: 60px;
				text-align: center;
				.el-button{
					margin-top: 30px;
					padding: 12px 40px;
				}
				.xuanzek{
					text-align: left;
				}
			}
		}
	}
}
@media screen and (max-width: 750px) {
	.mobphone{
		.mobphone-main{
			.gongdan{
				width: 100%;
				.dan{
					justify-content: left;
					.text{
						width: 1.5rem;
					}
					.inputbox{
						width: 5.4rem;
						.el-input{
							margin-left: 0;
						}
					}
				}
			}
		}
	}
}
</style>
<style lang="scss">
.mobphone{
	.el-input{
		.el-input__inner{
			height: 45px;
			line-height: 45px;
			font-size: 16px;
			border: none;
			padding: 0;
		}
	}
}
</style>
